import React, { useEffect, useState } from "react";
import CustomButton from "../../Commoncomponents/button";
import CustomCard from "../../Commoncomponents/card";
import Arrow from "../../../utils/Icon/Arrow.svg";
import Pointer from "../../../utils/Icon/Pointer.svg";
import Triangle from "../../../utils/Icon/Triangle.svg";
import ClockAnimation from "../../../utils/Icon/ClockAnimation.svg";
import { useLocation, useNavigate } from "react-router-dom";
import CustomBadge from "../../Commoncomponents/badge";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { contentFontSize_18, contentFontSize_20 } from "../../Typography/index";
import { contentFontsize_14 } from "../../Typography/index";
import { ButtonFontSize_18 } from "../../Typography/index";
import { ButtonFontSize_16 } from "../../Typography/index";
import { contentFontsize_14_xs_10 } from "../../Typography/index";
import { ButtonFontSize_16_xs_11 } from "../../Typography/index";
import { HeaderNavigationFontSize_20_xs_13 } from "../../Typography/index";
import ShopAssistantClock from "../../../utils/Icon/ShopAssistantClock.svg";

import "./ActiveOrderFrontPage.css";

function ActiveOrderFrontPage(props) {
  useEffect((storeid) => {
    props.getActiveOrdertlist(storeid)
  }, []);
  

  console.log("ChangetoActiveStatusSuccess",props.ChangetoActiveStatusSuccess)
  useEffect(()=>{
      if(props.ChangetoActiveStatusSuccess){
        props.getActiveOrdertlist(storeid); 
      }
  },[props.ChangetoActiveStatusSuccess])


// const [ActiveorderList,setActiveOrderList] = useState([])
//  useEffect(()=>{
//   setActiveOrderList([props &&
//     props.activeOrders &&
//     props.activeOrders.data &&
//     props.activeOrders.data.activeOrdersList])
//  },[])
  const ActiveorderList =
    ("props",
  props &&
    props.activeOrders &&
    props.activeOrders.data &&
    props.activeOrders.data.activeOrdersList
    );

  const storeid = localStorage.getItem('storeid')
  useEffect(()=>{
     props.getAllCount(storeid)
  },[])
  const count = props.allCount?.data?.getOrderStatusCount;
useEffect((storeid) => {
  

  props.getNewOrdertlist(storeid);
}, []);
const [newOrderList,setNewOrderList] = useState([])
useEffect(()=>{
  const orderList =
    ("props",
    props && 
      props.newOrders &&
      props.newOrders.data &&
      props.newOrders.data.newOrdersList);
  setNewOrderList(orderList)
})
useEffect((storeid)=>{
  props.getOrderCount(storeid)
},[])
const outReady = ("props",props&&props.OrderCount&&props.OrderCount.data&&props.OrderCount.data.outReadyOrdersCount)
useEffect((storeid) => {
  // Dispatch the action when the component mounts
props.getReadyOrdertlist(storeid)
}, []);
const[readyOrdersList,setReadyOrdersList] = useState([])
useEffect(()=>{
  const ReadyorderList =
    ("props",
    props &&
      props.readyOrders &&
      props.readyOrders.data &&
      props.readyOrders.data.readyOrdersList);
  setReadyOrdersList(ReadyorderList)
})
  const acceptState = JSON.parse(localStorage.getItem("buttonState")) || [];
  const [activeButton, setActiveButton] = useState("activeOrders");
  const isBadgeActive = activeButton;
  const [orderDatas, setOrderData] = useState([]);
  const [selectedActiveOrder, setSelectedActiveOrder] = useState(null);
  const ActiveOrderFrontPageData =
    JSON.parse(localStorage.getItem("acceptedOrders")) || [];

  function handleClickOne() {
    nav("/home/MyOrders");
  }
  const handleClickTwo = () => {
    setActiveButton("activeOrders");
  };
  const nav = useNavigate();
  const [selectedActiveOrderDetails, setSelectedActiveOrderDetails] = useState(
    []
  );
 
  function handleCardTwo(orderId) {
   
    const selectedActiveOrder = ActiveorderList.find(
      (order) => order.id === orderId
    );

    setSelectedActiveOrderDetails((prevSelected) => [
      ...prevSelected,
      selectedActiveOrder,
    ]);

    setSelectedActiveOrder(selectedActiveOrder);


    if (selectedActiveOrder && selectedActiveOrder.id) {
      nav("/home/MyOrders/ActiveOrdersDetails", {
        state: { orderid: selectedActiveOrder.id },
      });

      localStorage.setItem("Aid", JSON.stringify(selectedActiveOrder.id));
    } else {
    }
    
  }

  function handleClickThree() {
    nav("/home/MyOrders/ReadyForDelivery");
  }
  function handleOrderHistory() {
    nav("/home/MyOrders/OrderHistory");
  }
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matchessm = useMediaQuery(theme.breakpoints.down("md"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <div >
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            gap: matches ? "10px" : "24px",
            marginTop: "0px",
            marginLeft: "0px",
            width: "100%",
            paddingLeft: "0px",
          }}
        >
          <CustomButton
            width="82%"
            height="50px"
            borderRadius="15px"
            onClick={handleClickOne}
            color={activeButton === "newOrders" ? "white" : "#646464"}
            variant={activeButton === "newOrders" ? "contained" : "outlined"}
            background={
              activeButton === "newOrders" ? "#659B1B" : "transparent"
            }
            borderColor="#659B1B"
            matchbtn={matches && true}
          >
            <Typography
              sx={{ fontFamily: "Roboto", fontWeight: "500" }}
              fontSize={ButtonFontSize_18}
            >
              New Orders
            </Typography>
            <CustomBadge
              badgeContent={count?.newOrders}
              backgroundColor={isBadgeActive === "newOrders" ? "white" : "none"}
              color={isBadgeActive === "newOrders" ? "#659B1B" : "#646464"}
              borderColor={isBadgeActive === "newOrders" ? "none" : "#646464"}
              border="2px solid"
              fontSize={matchessm ? "8px" : "12px"}
              height={matchessm ? "15px" : "20px"}
              minWidth={matchessm ? "15px" : "20px"}
              borderRadius="50%"
              marginLeft={matchessm ? (matches ? "8px" : "20px") : "25px"}
            ></CustomBadge>
          </CustomButton>

          <CustomButton
            width="82%"
            height="50px"
            borderRadius="15px"
            color={activeButton === "activeOrders" ? "white" : "#646464"}
            variant={activeButton === "activeOrders" ? "contained" : "outlined"}
            onClick={handleClickTwo}
            borderColor={
              activeButton === "activeOrders" ? "#659B1B" : "#659B1B"
            }
            background={
              activeButton === "activeOrders" ? "#659B1B" : "transparent"
            }
            matchbtn={matchessm && true}
          >
            <Typography
              fontSize={ButtonFontSize_18}
              sx={{ fontFamily: "Roboto", fontWeight: "500" }}
            >
              Active Orders
            </Typography>

            <CustomBadge
              badgeContent={count?.activeOrders}
              backgroundColor={
                isBadgeActive === "activeOrders" ? "white" : "none"
              }
              color={isBadgeActive === "activeOrders" ? "#659B1B" : "#646464"}
              borderColor={
                isBadgeActive === "activeOrders" ? "none" : "#646464"
              }
              border="2px solid"
              fontSize={matchessm ? "8px" : "12px"}
              height={matchessm ? "15px" : "20px"}
              minWidth={matchessm ? "15px" : "20px"}
              borderRadius="50%"
              marginLeft={matchessm ? (matches ? "8px" : "20px") : "25px"}
            ></CustomBadge>
          </CustomButton>

          <CustomButton
            width="82%"
            height="50px"
            borderRadius="15px"
            variant={
              activeButton === "readyForDelivery" ? "contained" : "outlined"
            }
            onClick={handleClickThree}
            color={activeButton === "readyForDelivery" ? "white" : "#646464"}
            borderColor={
              activeButton === "readyForDelivery" ? "#659B1B" : "#659B1B"
            }
            background={
              activeButton === "readyForDelivery" ? "#659B1B" : "transparent"
            }
          >
            <Typography
              fontSize={ButtonFontSize_18}
              sx={{ fontFamily: "Roboto", fontWeight: "500" }}
            >
              Ready for Delivery
            </Typography>
            <CustomBadge
              badgeContent={outReady}
              backgroundColor={
                isBadgeActive === "readyForDelivery" ? "white" : "none"
              }
              color={
                isBadgeActive === "readyForDelivery" ? "#659B1B" : "#646464"
              }
              borderColor={
                isBadgeActive === "readyForDelivery" ? "none" : "#646464"
              }
              border="2px solid"
              fontSize={matchessm ? "8px" : "12px"}
              height={matchessm ? "15px" : "20px"}
              minWidth={matchessm ? "15px" : "20px"}
              borderRadius="50%"
              marginLeft={matchessm ? (matches ? "8px" : "20px") : "25px"}
            ></CustomBadge>
          </CustomButton>
        </div>
      </div>
      <Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "30px",
            marginRight: upXlmatch ? "0px" : "0px",
          }}
        >
          <Typography
            sx={{
              color: "#85BE49",
              fontWeight: "600",
              textDecoration: "underline",
              cursor: "pointer",
              fontFamily: "Roboto",
            }}
            onClick={() => {
              handleOrderHistory();
            }}
            fontSize={contentFontSize_20}
          >
            {" "}
            Order History
          </Typography>
        </div>
      </Grid>
      
        <div style={{ marginTop: "20px" }} className='containerscrollflex'>
          <div  className='scrollable-content'>
          
          {ActiveorderList?.length === 0 ? (
            "No results found"
          ) : (
            <>
            {ActiveorderList?.map((orderData) => (
              <>
            <div>
              <CustomCard
                borderLeft="8px solid #1D89E1"
                width={
                  upXlmatch
                    ? "100%"
                    : xlmatch
                    ? "100%"
                    : matcheslg
                    ? "100%"
                    : matchessm
                    ? "100%" 
                    : matches
                    ? "100%"
                    : "0%"
                }
                height="120px"
                padding="20px 0px 5px 30px"
                marginLeft={
                  upXlmatch
                    ? "0px"
                    : matchessm
                    ? "0px"
                    : matches
                    ? "0px"
                    : "0px"
                }
                onClick={() => handleCardTwo(orderData.id)}
                boxShadow="none"
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={4} sx={{ paddingLeft: "0px" }}>
                    <Grid
                      item
                      xs={4}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                      paddingLeft="0px"
                    >
                      <div
                        className="ActiveOrderColumnFlex"
                        style={{
                          padding:
                            window.innerWidth < 600
                              ? "0px 0px 10px 0px"
                              : "5px 0px 10px 25px",
                        }}
                      >
                        <Typography
                          fontSize={contentFontSize_18}
                          className="orderIdFontStyleActive"
                        >
                          #{orderData?.orderNumber}
                        </Typography>
                        {/* {orderData?.stats[1]?.map((item, index)=>{ */}
                          
                        <Typography
                        // key={index}
                          fontSize={contentFontsize_14_xs_10}
                          className="dateFontStyleActive"
                        >
                           
                          {orderData?.stats[1]?.created},{orderData?.stats[1]?.createdTime}
                        </Typography>
                      
                        {/* })} */}
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={ShopAssistantClock}
                            alt="clock icon"
                            width={matcheslg && matches ? "10px" : "15px"}
                          />{" "}
                          <Typography
                            fontSize={contentFontsize_14_xs_10}
                            className="AssistantFontStyle1"
                          >
                            {orderData?.preparationTime} mins
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                      sx={{ paddingTop: "23px" }}
                    >
                      <div className="inProgressActive">
                        <CustomButton
                          variant="contained"
                          background="#1D89E1"
                          width={matches ? "70px" : "140px"}
                          height={matches ? "30px" : "40px"}
                        >
                          <Typography
                            fontSize={ButtonFontSize_16_xs_11}
                            className="dateFontStyle1"
                          >
                            In Progress
                          </Typography>
                        </CustomButton>
                      </div>
                    </Grid>
                    <Grid item xs={3} sm={5} md={5} lg={5} xl={5}></Grid>
                    <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
                      <div className="inProgressActive1">
                        <img
                          src={Arrow}
                          alt="arrow icon"
                          style={{ marginBottom: "10px" }}
                          height={
                            upXlmatch
                              ? "20px"
                              : xlmatch
                              ? lgmatch
                                ? mdmatch
                                  ? smmatch
                                    ? xsmatch
                                      ? "12px"
                                      : "12px"
                                    : "14px"
                                  : "16px"
                                : "18px"
                              : "20px"
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Box>
                {/* <div
                  key={orderData?.orderId}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding:
                      window.innerWidth < 600
                        ? "5px 0px 10px 0px"
                        : "5px 0px 10px 30px",
                  }}
                >
                  <div>
                    <Typography
                      sx={{ fontWeight: "600", fontFamily: "Roboto" }}
                      fontSize={contentFontSize_20}
                    >
                      #{orderData?.orderNumber}
                    </Typography>
                    <Typography
                      fontSize={contentFontsize_14}
                      sx={{ fontWeight: "400", fontFamily: "Roboto" }}
                    >
                      {orderData?.deliveryDate},{orderData.deliveryTime}
                    </Typography>
 
                    <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={ShopAssistantClock}
                          alt="clock icon"
                          width={matcheslg && matches ? "10px" : "15px"}
                        />{" "}
                        <Typography
                          fontSize={contentFontsize_14_xs_10}
                          className="AssistantFontStyle1"
                        >
                          {orderData?.preparationTime} mins
                        </Typography>
                      </div>
                     
                  </div>
                    <div style={{marginRight:"450px"}}>
                    <CustomButton
                        variant="contained"
                        background="#1D89E1"
                        width={matches ? "60px" : "120px"}
                        height={matches ? "30px" : "50px"}
                      >
                        <Typography
                          fontSize={ButtonFontSize_16_xs_11}
                          className="dateFontStyle1"
                        >
                          In Progress
                        </Typography>
                      </CustomButton>
                    </div>
                  <div>
                  <img
                    src={Arrow}
                   
                    alt="arrow"
                    style={{
                      float: "right",
                      marginRight:"20px",
                      marginTop: "20px",
                      cursor: "pointer",
                      height: matches ? "15px" : "19px",
                    }}
                  ></img>
                </div>
                </div> */}
              </CustomCard>
            </div>
            </>
            ))}
            </>
          )}
          
          </div>
        </div>
     
    </>
  );
}

export default ActiveOrderFrontPage;
