// import React, { useState } from 'react'
// import CustomButton from '../../Commoncomponents/button';
// import CustomCard from '../../Commoncomponents/card';
// import { Container, Typography } from '@mui/material';
// import Arrow from '../../../utils/Icon/Arrow.svg';
// import ClockAnimation from '../../../utils/Icon/ClockAnimation.svg';
// import { useNavigate } from 'react-router-dom';
// import CustomBadge from '../../Commoncomponents/badge';
// function OutForDeliveryFrontPage() {
//     const OutForDelivery = JSON.parse(localStorage.getItem('OutForDelivery')) || [];
//     const OrderReady = JSON.parse(localStorage.getItem('OrderReady')) || [];
//     const [activeButton, setActiveButton] = useState("readyForDelivery");
//     const isBadgeActive = activeButton === 'newOrders';
//     const [markUsRead, setMarkUsRead] = useState(true);
//     const [clickedItems, setClickedItems] = useState(Array(OrderReady.length).fill(false));
//     const nav = useNavigate()
//     function handleClickOne(){
//         nav("/home/MyOrders")
//       }
//       function handleClickTwo(){
//         nav("/home/MyOrders/ActiveOrders")
//       }
//       function handleClickThree(){
//         setActiveButton("readyForDelivery")
//         nav("/home/MyOrders//ReadyForDelivery")
//         setMarkUsRead(false);
//       }
//       const handleAllClick = () => {
//              setMarkUsRead(false);
//       };
//       const handleoutForDelivery = () => {
//             setMarkUsRead(false);
//       };
//       const handleTotalColorChange = () => {
//             setClickedItems(Array(OrderReady.length).fill(true));
//        };
//        function handleCardThree(){
//                nav("/home/MyOrders/ReadyForDeliveryDetails")
//                //console.log(selectedDataFromActiveOrders)
//                setMarkUsRead(true)
//        }
//        function handleCardFour(){
//                 nav("/home/MyOrders/OutForDeliveryDetails")
//                 //JSON.parse(localStorage.getItem('orderDetails'));
//      }
//   return (
//     <div>
//     <div className='btnStyles'>

//     <CustomButton
//     width='330px'
//       height='50px'
//       borderRadius='15px'
//       onClick={handleClickOne}
//       color={activeButton === 'newOrders' ? 'white' : '#646464'}
//       variant={activeButton === 'newOrders' ? 'contained' : 'outlined'}
//       background={activeButton === 'newOrders' ? '#659B1B': 'transparent'}
//       borderColor='#659B1B'
//    >
//      New Orders
//     <CustomBadge badgeContent='2' backgroundColor={isBadgeActive ? 'white':'none'} color={isBadgeActive?'#659B1B': '#646464'} borderColor={isBadgeActive?'none':'#646464'} border='2px solid '></CustomBadge>
//   </CustomButton>

//   <CustomButton
//     width='330px'
//       height='50px'
//       borderRadius='15px'
//       color={activeButton === 'activeOrders' ? 'white' : '#646464'}
//       variant={activeButton === 'activeOrders' ? 'contained' : 'outlined'}
//       onClick={handleClickTwo}
//       borderColor={activeButton === 'activeOrders' ? '#659B1B' : '#659B1B'}
//       background={activeButton === 'activeOrders' ? '#659B1B': 'transparent'}
//     >
//      Active Orders
//     </CustomButton>

//     <CustomButton
//     width='330px'
//      height='50px'
//      borderRadius='15px'
//       variant={activeButton === 'readyForDelivery' ? 'contained' : 'outlined'}
//       onClick={handleClickThree}
//       color={activeButton === 'readyForDelivery' ? 'white' : '#646464'}
//       borderColor={activeButton === 'readyForDelivery' ? '#659B1B' : '#659B1B'}
//       background={activeButton === 'readyForDelivery' ? '#659B1B': 'transparent'}
//     >
//       Ready for Delivery
//     </CustomButton>
//     </div>
//     <>
// <div style={{ marginTop: '50px' }}>
// <Container>
//   <Typography variant="subtitle1" gutterBottom style={{ display: 'flex', gap: '40px' }}>
//     <div onClick={handleAllClick} style={{ borderBottom: markUsRead ? '' : '2px solid #659B1B', cursor: 'pointer', fontWeight: 'bold' }}>
//       Ready For Delivery
//     </div>
//     <div
//       onClick={() => {
//         handleoutForDelivery();
//         handleTotalColorChange();
//       }}
//       style={{ borderBottom: markUsRead ? '2px solid #659B1B' : '', cursor: 'pointer', fontWeight: 'bold' }}
//     >
//       Out For Delivery
//     </div>
//   </Typography>
// </Container>

// {OrderReady.length === 0 ? (
//   <Typography variant="subtitle1" gutterBottom style={{ marginLeft: "30px", fontWeight: 'bold', marginTop: '30px' }}>
//     No new notifications
//   </Typography>
// ) : !markUsRead ? (
//   <>
//     <Container style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '50px' }}>
//       {OrderReady.map((item, index) => (
//         <CustomCard key={index} borderLeft='8px solid #FF9900' width='88%' height='120px' padding='10px'>
//           <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
//             <div>
//               <p>{item.orderId}</p>
//               <p>{item.deliveryDate}</p>
//               {/* <p>{item.time}</p> */}
//             </div>

//             <div style={{ marginTop: '30px', textTransform: 'lowercase' }}>
//               <CustomButton variant='contained' background='#FF9900' width='120px' height='50px' textColor='white'>
//                 Ready For Delivery
//               </CustomButton>
//             </div>
//             <div style={{ marginTop: '15px' }}>
//               <img src={ClockAnimation} alt='animation'></img>
//             </div>
//             <div style={{ justifyItems: 'space-between', display: 'flex', flexDirection: 'row' }}>
//               <img src={Arrow} alt='arrow' style={{ float: 'right', marginTop: '-13px', cursor: 'pointer' }} onClick={handleCardThree}></img>
//             </div>
//           </div>
//         </CustomCard>
//       ))}
//     </Container>
//   </>
// ) : (
//   <>

//     <Container style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '50px' }}>
//       {OutForDelivery.map((item, index) => (
//         <CustomCard key={index} borderLeft='8px solid #85BE49' width='88%' height='120px' padding='10px'>
//           <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
//             <div>
//               <p>{item.orderId}</p>
//               <p>{item.deliveryDate}</p>
//               <p>{item.time}</p>
//             </div>

//             <div style={{ marginTop: '30px', textTransform: 'lowercase' }}>
//               <CustomButton variant='contained' background='#85BE49' width='120px' height='50px' textColor='white'>
//                 Out for delivery
//               </CustomButton>
//             </div>
//             <div style={{ marginTop: '15px' }}>
//               <img src={ClockAnimation} alt='animation'></img>
//             </div>
//             <div style={{ justifyItems: 'space-between', display: 'flex', flexDirection: 'row' }}>
//               <img src={Arrow} alt='arrow' style={{ float: 'right', marginTop: '-13px', cursor: 'pointer' }} onClick={handleCardFour}></img>
//             </div>
//           </div>
//         </CustomCard>
//       ))}
//     </Container>

//     {/* {OutForDelivery.buttonState && (
//     <OutForDeliveryFrontPage/>
//     )} */}
//   </>
// )}
// </div>
// </>

// </div>

//   )
// }

// export default OutForDeliveryFrontPage

import React, { useEffect, useState } from "react";
import CustomButton from "../../Commoncomponents/button";
import CustomBadge from "../../Commoncomponents/badge";
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomCard from "../../Commoncomponents/card";
import Arrow from "../../../utils/Icon/Arrow.svg";
import ClockAnimation from "../../../utils/Icon/ClockAnimation.svg";
import { ButtonFontSize_18 } from "../../Typography/index";
import { contentFontsize_14_xs_10 } from "../../Typography/index";
import { ButtonFontSize_16_xs_11 } from "../../Typography/index";
import { HeaderNavigationFontSize_20_xs_13 } from "../../Typography/index";
import ShopAssistantClock from "../../../utils/Icon/ShopAssistantClock.svg";
import { contentFontSize_20 } from "../../Typography/index";
import "./OutForDeliveryFrontPage.css";
function OutForDeliveryFrontPage(props) {
  useEffect((storeid) => {
    // Dispatch the action when the component mounts

     props.getOutForDeliverytlist(storeid);
  }, []);
  // const [outForDelivery,setOutForDelivery] = useState([])
  // useEffect(()=>{
  const OutForDeliveryList =
    ("props",
    props &&
      props.outForDeliveryOrders &&
      props.outForDeliveryOrders.data &&
      props.outForDeliveryOrders.data.outOfDeliveryList);
  //setOutForDelivery(OutForDeliveryList)
// })
useEffect((storeid)=>{
  props.getActiveOrdertlist(storeid)
},[])
useEffect((storeid)=>{
  if(props.ChangetoDeliveryStatusSuccess){
    props.getOutForDeliverytlist(storeid); 
  }
},[props.ChangetoDeliveryStatusSuccess])

  const ActiveorderList =
    ("props",
    props &&
      props.activeOrders &&
      props.activeOrders.data &&
      props.activeOrders.data.activeOrdersList);
  useEffect((storeid) => {
    // Dispatch the action when the component mounts
    props.getReadyOrdertlist(storeid)
    // console.log("details", props.getActiveOrdertlist(storeid));
  }, []);
 useEffect((storeid)=>{
    props.getNewOrdertlist(storeid)
 },[])
  const orderList =
    ("props",
    props &&
      props.newOrders &&
      props.newOrders.data &&
      props.newOrders.data.newOrdersList);


  useEffect((storeid)=>{
    props.getOrderCount(storeid)
  },[])
  const outReady = ("props",props&&props.OrderCount&&props.OrderCount.data&&props.OrderCount.data.outReadyOrdersCount)
  useEffect((storeid) => {
    // Dispatch the action when the component mounts

  }, []);

  // const [details, setDetails] = useState([
  //   {
  //     orderNumber: "1032",
  //     deliveryDate: "Fri Sep 19 2023",
  //     deliveryTime: "3:00-9:00pm",
  //   },
  //   {
  //     orderNumber: "1018",
  //     deliveryDate: "Fri Sep 19 2023",
  //     deliveryTime: "3:00-9:00pm",
  //   },
  // ]);

  const OutForDelivery =
    JSON.parse(localStorage.getItem("OutForDelivery")) || [];
  const [activeButton, setActiveButton] = useState("readyForDelivery");
  const isBadgeActive = activeButton;

  const [clickedItems, setClickedItems] = useState(
    Array(OutForDelivery.length).fill(false)
  );

  const [ready, setReady] = useState(false);
  const [out, setOut] = useState(true);
  const nav = useNavigate();
  function handleClickOne() {
    nav("/home/MyOrders");
  }
  function handleClickTwo() {
    nav("/home/MyOrders/ActiveOrders");
  }
  function handleClickThree() {
    setActiveButton("readyForDelivery");
    nav("/home/MyOrders/OutForDelivery");
    //setReady(true)
    //setMarkUsRead(false);
  }
  function handleAllClick() {
    setReady(true);
    setOut(false);
    nav("/home/MyOrders/ReadyForDelivery");
  }
  function handleoutForDelivery() {
    //setOut(true)
    setReady(false);
  }
  function handleTotalColorChange() {
    setClickedItems(Array(OutForDelivery.length).fill(true));
  }
  const [selectedDeliveryOrder,setSelectedDeliveryOrder]=useState([])
  const [selectedDeliveryOrderDetails,setSelectedDeliveryOrderDetails] = useState([])
  function handleCardFour(orderId) {
    const selectedDeliveryOrder = OutForDeliveryList.find(
      (order) => order.id === orderId
    );
    
    setSelectedDeliveryOrderDetails((prevSelected) => [
      ...prevSelected,
      selectedDeliveryOrder,
    ]);
    setSelectedDeliveryOrder(selectedDeliveryOrder);

      
        // Navigate to another component passing order id in state
        if (selectedDeliveryOrder && selectedDeliveryOrder.id) {
          nav("/home/MyOrders/OutForDeliveryDetails", { state: { orderid: selectedDeliveryOrder.id } });
          
          // Store selected order ID in localStorage
          localStorage.setItem("Did",(selectedDeliveryOrder.id));
        } else {
          console.error("Invalid selectedDeliveryOrder or selectedDeliveryOrder.id");
        }  

    
    
    
    // nav("/home/MyOrders/OutForDeliveryDetails");
  }
  function handleOrderHistoryClick() {
    const outfor = "Out for Delivery"
    nav("/home/MyOrders/OrderHistory", { state: { name2: outfor } })
  }
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matchessm = useMediaQuery(theme.breakpoints.down("md"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          gap: matches ? "10px" : "24px",
         
        }}
      >
        <CustomButton
          width="98%"
          height="50px"
          borderRadius="15px"
          onClick={handleClickOne}
          color={activeButton === "newOrders" ? "white" : "#646464"}
          variant={activeButton === "newOrders" ? "contained" : "outlined"}
          background={activeButton === "newOrders" ? "#659B1B" : "transparent"}
          borderColor="#659B1B"
          matchbtn={matches && true}
        >
          <Typography
            sx={{ fontFamily: "Roboto", fontWeight: "500" }}
            fontSize={ButtonFontSize_18}
          >
            New Orders
          </Typography>
          <CustomBadge
            badgeContent={orderList && orderList?.length}
            backgroundColor={isBadgeActive === "newOrders" ? "white" : "none"}
            color={isBadgeActive === "newOrders" ? "#659B1B" : "#646464"}
            borderColor={isBadgeActive === "newOrders" ? "none" : "#646464"}
            border="2px solid"
            fontSize={matchessm ? "8px" : "12px"}
            height={matchessm ? "15px" : "20px"}
            minWidth={matchessm ? "15px" : "20px"}
            borderRadius="50%"
            marginLeft={matchessm ? (matches ? "8px" : "20px") : "25px"}
          ></CustomBadge>
        </CustomButton>

        <CustomButton
          width="98%"
          height="50px"
          borderRadius="15px"
          color={activeButton === "activeOrders" ? "white" : "#646464"}
          variant={activeButton === "activeOrders" ? "contained" : "outlined"}
          onClick={handleClickTwo}
          borderColor={activeButton === "activeOrders" ? "#659B1B" : "#659B1B"}
          background={
            activeButton === "activeOrders" ? "#659B1B" : "transparent"
          }
          matchbtn={matchessm && true}
        >
          <Typography
            fontSize={ButtonFontSize_18}
            sx={{ fontFamily: "Roboto", fontWeight: "500" }}
          >
            Active Orders
          </Typography>

          <CustomBadge
            badgeContent={ActiveorderList && ActiveorderList?.length}
            backgroundColor={
              isBadgeActive === "activeOrders" ? "white" : "none"
            }
            color={isBadgeActive === "activeOrders" ? "#659B1B" : "#646464"}
            borderColor={isBadgeActive === "activeOrders" ? "none" : "#646464"}
            border="2px solid"
            fontSize={matchessm ? "8px" : "12px"}
            height={matchessm ? "15px" : "20px"}
            minWidth={matchessm ? "15px" : "20px"}
            borderRadius="50%"
            marginLeft={matchessm ? (matches ? "8px" : "20px") : "25px"}
          ></CustomBadge>
        </CustomButton>

        <CustomButton
          width="98%"
          height="50px"
          borderRadius="15px"
          variant={
            activeButton === "readyForDelivery" ? "contained" : "outlined"
          }
          onClick={handleClickThree}
          color={activeButton === "readyForDelivery" ? "white" : "#646464"}
          borderColor={
            activeButton === "readyForDelivery" ? "#659B1B" : "#659B1B"
          }
          background={
            activeButton === "readyForDelivery" ? "#659B1B" : "transparent"
          }
        >
          <Typography
            fontSize={ButtonFontSize_18}
            sx={{ fontFamily: "Roboto", fontWeight: "500" }}
          >
            Ready for Delivery
          </Typography>
          <CustomBadge
            badgeContent={outReady}
            backgroundColor={
              isBadgeActive === "readyForDelivery" ? "white" : "none"
            }
            color={isBadgeActive === "readyForDelivery" ? "#659B1B" : "#646464"}
            borderColor={
              isBadgeActive === "readyForDelivery" ? "none" : "#646464"
            }
            border="2px solid"
            fontSize={matchessm ? "8px" : "12px"}
            height={matchessm ? "15px" : "20px"}
            minWidth={matchessm ? "15px" : "20px"}
            borderRadius="50%"
            marginLeft={matchessm ? (matches ? "8px" : "20px") : "25px"}
          ></CustomBadge>
        </CustomButton>
      </div>
      <div
        style={{ marginTop: "50px", marginLeft: matchessm ? "0px" : "0px" }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md="auto">
            <Typography
              fontSize={contentFontSize_20}
              variant="subtitle1"
              gutterBottom
              style={{ display: "flex", gap: "40px" }}
            >
              <div
                onClick={handleAllClick}
                style={{
                  borderBottom: ready ? "2px solid #659B1B" : "none",
                  color: ready ? "#000000" : "#8B8989",
                  cursor: "pointer",
                  fontWeight: "700",
                  fontFamily: "Nunito",
                }}
              >
                <Typography
                  fontSize={contentFontSize_20}
                  sx={{ fontWeight: "700", fontFamily: "Nunito" }}
                >
                  {" "}
                  Ready For Delivery
                </Typography>
              </div>
              <div
                onClick={() => {
                  handleoutForDelivery();
                  handleTotalColorChange();
                }}
                style={{
                  borderBottom: out ? "2px solid #659B1B" : "none",
                  color: out ? "#000000" : "#8B8989",
                  cursor: "pointer",
                  fontWeight: "700",
                  fontFamily: "Nunito",
                }}
              >
                <Typography
                  fontSize={contentFontSize_20}
                  sx={{ fontWeight: "700", fontFamily: "Nunito" }}
                >
                  Out For Delivery
                </Typography>
              </div>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md="auto"
            sx={{
              marginRight: matchessm
                ? "20px"
                : matches
                ? "15px"
                : xlmatch
                ? "0px"
                : lgmatch
                ? "0px"
                : upXlmatch
                ? "0px"
                : "0px",
            }}
          >
            <Typography
              component="div"
              sx={{
                color: "#85BE49",
                fontWeight: "600",
                textDecoration: "underline",
                cursor: "pointer",
                fontFamily: "Roboto",

                textAlign: xsmatch
                  ? "left"
                  : matches
                  ? "left"
                  : matchessm
                  ? "left"
                  : "right",
              }}
              fontSize={contentFontSize_20}
              onClick={() => handleOrderHistoryClick()}
            >
              Order History
            </Typography>
          </Grid>
        </Grid>
      </div>

      {out && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              marginTop: '0px'
            }}
            className='containerscrollflex'
          >
            <div  className='scrollable-content'>
              {OutForDeliveryList?.length === 0 ? (
                "No results found"
              ):(
                <>
              {OutForDeliveryList?.map((item, index) => (
              <>
                {/* {item.lastStatus === "Out-For-Delivery" && ( */}
                  <CustomCard
                    key={index}
                    borderLeft="8px solid #85BE49"
                    padding="20px 0px 20px 48px"
                    width={
                      upXlmatch
                        ? "100%"
                        : xlmatch
                        ? "100%"
                        : matcheslg
                        ? "100%"
                        : matchessm
                        ? "100%"
                        : matches
                        ? "100%"
                        : "100%"
                    }
                    height="120px"
                    
                    marginLeft={upXlmatch?"0px":matchessm?"0px":matches?"0px":"0px"}
                    onClick={() => handleCardFour(item.id)}
                    boxShadow="none" 
                  >
                    <Box sx={{ flexGrow: 1,paddingLeft:"0px" }}>
                      <Grid container spacing={4} sx={{paddingLeft:"0px"}}> 
                        <Grid item xs={4} sm={3} md={3} lg={3} xl={3} sx={{paddingLeft:"0px"}}>
                          <div className="OutColumnFlex">
                            <Typography 
                              fontSize={HeaderNavigationFontSize_20_xs_13}
                              className="orderIdFontStyle"
                            >
                              #{item.orderNumber}
                            </Typography>
                            <Typography
                              fontSize={contentFontsize_14_xs_10}
                              className="dateFontStyle1"
                            >
                              {item?.stats[4]?.created},{item?.stats[4]?.createdTime}
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={ShopAssistantClock}
                                alt="clock icon"
                                width={matcheslg && matches ? "10px" : "15px"}
                              />{" "}
                              <Typography
                                fontSize={contentFontsize_14_xs_10}
                                className="AssistantFontStyle1"
                              >
                                {item?.preparationTime} mins
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                          <div className="inProgressOut">
                            <CustomButton
                              variant="contained"
                              background="#85BE49"
                             
                            >
                              <Typography
                                fontSize={ButtonFontSize_16_xs_11}
                                className="dateFontStyle2"
                                width={matches ? "70px" : "140px"}
                                height={matches ? "40px" : "30px"}
                              //  padding="1px 2px 3px 4px"
                              >
                                Out for Delivery
                              </Typography>
                            </CustomButton>
                          </div>
                        </Grid>
                        <Grid item  xs={3} sm={5} md={5} lg={5} xl={5}>
                          {/* <div className="inProgressOut">
                            {" "}
                            <img
                              src={ClockAnimation}
                              alt="clockAnimation"
                              width={matches ? "60px" : "110px"}
                            />
                          </div> */}
                        </Grid>
                        <Grid item  xs={2} sm={1} md={1} lg={1} xl={1}>
                          <div className="inProgressOut1">
                            <img
                              src={Arrow}
                              alt="arrow icon"
                              style={{marginTop:"5px"}}
                              height={
                                upXlmatch
                                  ? "20px"
                                  : xlmatch
                                  ? lgmatch
                                    ? mdmatch
                                      ? smmatch
                                        ? xsmatch
                                          ? "12px"
                                          : "12px"
                                        : "14px"
                                      : "16px"
                                    : "18px"
                                  : "20px"
                              }
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </CustomCard>
                {/* )} */}
              </>
            ))}
            </>
          )}

            </div>
          </div>
        </>
      )}
    </div>       
  );
}

export default OutForDeliveryFrontPage;
