import * as yup from "yup";
import { isSameHour, isSameMinute } from "date-fns";
import Holidays from "../Forms/Holidays";

export const validationSchema = yup.object({
  // holiday: yup.number().required("Holidays is required"),

  minAmount: yup
    .string()
    .trim("Space not allowed")
    .required("Minimum Order value is required")
    .test("valid-number", "Only numbers are allowed", (value) => {
      const numValue = parseFloat(value);
      return !isNaN(numValue);
    })
    .test("not-negative", "Positive numbers are allowed", (value) => {
      const numValue = parseFloat(value);
      return numValue >= 0;
    })
    .test("not-zero", "Value cannot be zero", (value) => {
      const numValue = parseFloat(value);
      return numValue !== 0;
    })
    .test("no-alphabet", "Only numbers are allowed", (value) => {
      return /^\d+$/.test(value);
    }),
  packingCharge: yup
    .string()
    .trim("Space not allowed")
    .required("Packing Charge value is required")
    .test("valid-number", "Only numbers are allowed", (value) => {
      const numValue = parseFloat(value);
      return !isNaN(numValue);
    })
    .test("not-negative", "Positive numbers are allowed", (value) => {
      const numValue = parseFloat(value);
      return numValue >= 0;
    })
    .test("not-zero", "Value cannot be zero", (value) => {
      const numValue = parseFloat(value);
      return numValue !== 0;
    })
    .test("no-alphabet", "Only numbers are allowed", (value) => {
      return /^\d+$/.test(value);
    }),
  preparationTime: yup
    .string()
    .trim("Space not allowed")
    .required("Preparation Time is required")
    .test("valid-number", "Only numbers are allowed", (value) => {
      const numValue = parseFloat(value);
      return !isNaN(numValue);
    })
    .test("not-negative", "Positive numbers are allowed", (value) => {
      const numValue = parseFloat(value);
      return numValue >= 0;
    })
    .test("not-zero", "Value cannot be zero", (value) => {
      const numValue = parseFloat(value);
      return numValue !== 0;
    })
    .test("no-alphabet", "Only numbers are allowed", (value) => {
      return /^\d+$/.test(value);
    }),
    // deliveryMaxDistance: yup.number().required("Delivery distance is required"),

  openingTime: yup.date().required("Opening Time is required"),
  closingTime: yup
  .date()
  .required("Closing Time is required"),

  // deliveryType: yup
  //   .array()
  //   // .of(yup.string().required('Each delivery type must be a string'))
  //   .required("delivery type is required")
  //   .min(1, "delivery type must have at least one item"),

  // minAmount: yup
  //   .string()
  //   .trim("Space not allowed")
  //   .required("Minimum Order value is required")
  //   .test("valid-number", "Only numbers are allowed", (value) => {
  //     const numValue = parseFloat(value);
  //     return !isNaN(numValue); // Check if it's a valid number
  //   })
  //   .test("not-negative", "Positive numbers are allowed", (value) => {
  //     const numValue = parseFloat(value);
  //     return numValue >= 0; // Check if it's not negative
  //   })
  //   .test("not-zero", "Value cannot be zero", (value) => {
  //     const numValue = parseFloat(value);
  //     return numValue !== 0; // Check if it's not zero
  //   })
  //   .test("no-alphabet", "Only numbers are allowed", (value) => {
  //     return /^\d+$/.test(value); // Check if there are only numbers
  //   }),
  //   packingCharge: yup
  //   .string()
  //   .trim("Space not allowed")
  //   .required("packingCharge value is required")
  //   .test("valid-number", "Only numbers are allowed", (value) => {
  //     const numValue = parseFloat(value);
  //     return !isNaN(numValue); // Check if it's a valid number
  //   })
  //   .test("not-negative", "Positive numbers are allowed", (value) => {
  //     const numValue = parseFloat(value);
  //     return numValue >= 0;
  //   })
  //   .test("not-zero", "Value cannot be zero", (value) => {
  //     const numValue = parseFloat(value);
  //     return numValue !== 0; // Check if it's not zero
  //   })
  //   .test("no-alphabet", "Only numbers are allowed", (value) => {
  //     return /^\d+$/.test(value); // Check if there are only numbers
  //   }),
});
