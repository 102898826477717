import { gql } from "@apollo/client";
export const SET_STORE_NAME = "SET_STORE_NAME";
export const SET_STORE_DESCRIPTION = "SET_STORE_DESCRIPTION";
export const SET_STORE_LOCATION = "SET_STORE_LOCATION";
export const SET_STORE_COORDINATES = "SET_STORE_COORDINATES";
export const GET_IMAGE_SIGN = gql`
  mutation ($file: Upload!) {
    signS3(file: $file) {
      ETag
      Location
      Key
      Bucket
    }
  }
`;

export const SIGNUP_MUTATION = gql`
  mutation (
    $firstName: String!
    $lastName: String!
    $mobileNumber: String!
    $secondaryNumber: String
    $emailAddress: String!
    $password: String!
    $PANnumber: String
    $GSTnumber: String
    $storeCoordinates: locationInput!
    $storeLocation: String!
    $storeName: String!
    $storeDescription: String!
    $storeLogoUrl: String!
    $storeManagerFirstName: String!
    $storeManagerLastName: String!
    $storeManagerMobile: String!
    $storeManagerPhone: String
    $deliveryType: [String]!
    $deliveryMaxDistance: Float!
    $deliveryCharge: [deliveryChargeInput]
    $paymentSupported: [String]!
    $deliveryDays: [deliveryDaysInput]!
    $businessTypeId: [Int]
    $holidays: [Int]
    $bankDetails: bankDetailsInput
    $minAmount: Float
    $openingTime: String
    $closingTime: String
    $fssaiNo: String
    $fssaiUrl: String
    $licenseUrl: String
    $aadharUrl: String
    $GSTurl: String
    $subscription: subscriptionInput!
    $sameDayDelivery: Boolean
    $deliveryAfter: Int
  ) {
    createRetailer(
      bankDetails: $bankDetails
      minAmount: $minAmount
      firstName: $firstName
      lastName: $lastName
      mobileNumber: $mobileNumber
      secondaryNumber: $secondaryNumber
      emailAddress: $emailAddress
      password: $password
      PANnumber: $PANnumber
      GSTnumber: $GSTnumber
      holidays: $holidays
      storeCoordinates: $storeCoordinates
      storeLocation: $storeLocation
      storeName: $storeName
      storeDescription: $storeDescription
      storeLogoUrl: $storeLogoUrl
      storeManagerFirstName: $storeManagerFirstName
      storeManagerLastName: $storeManagerLastName
      storeManagerMobile: $storeManagerMobile
      storeManagerPhone: $storeManagerPhone
      deliveryType: $deliveryType
      deliveryMaxDistance: $deliveryMaxDistance
      deliveryCharge: $deliveryCharge
      paymentSupported: $paymentSupported
      deliveryDays: $deliveryDays
      businessTypeId: $businessTypeId
      openingTime: $openingTime
      closingTime: $closingTime
      fssaiNo: $fssaiNo
      fssaiUrl: $fssaiUrl
      licenseUrl: $licenseUrl
      aadharUrl: $aadharUrl
      GSTurl: $GSTurl
      subscription: $subscription
      sameDayDelivery: $sameDayDelivery
      deliveryAfter: $deliveryAfter
    ) {
      id
    }
  }
`;
export const SIGNUP_P = gql`
  mutation (
    $firstName: String!
    $lastName: String!
    $mobileNumber: String!
    $secondaryNumber: String
    $emailAddress: String!
    $password: String!
    $GSTnumber: String
    $storeCoordinates: locationInput!
    $storeLocation: String!
    $storeName: String!
    $holidays: [Int]
    $storeDescription: String!
    $storeLogoUrl: String!
    $storeManagerFirstName: String!
    $storeManagerLastName: String!
    $storeManagerMobile: String!
    $storeManagerPhone: String
    $deliveryType: [String]!
    $deliveryMaxDistance: Float!
    $deliveryCharge: [deliveryChargeInput]
    $paymentSupported: [String]!
    $deliveryDays: [deliveryDaysInput]!
    $businessTypeId: [Int]
    $bankDetails: bankDetailsInput
    $minAmount: Float
    $openingTime: String
    $closingTime: String
    $fssaiNo: String
    $fssaiUrl: String
    $licenseUrl: String
    $aadharUrl: String
    $GSTurl: String
    $subscription: subscriptionInput!
    $sameDayDelivery: Boolean
    $deliveryAfter: Int
  ) {
    createRetailer(
      GSTurl: $GSTurl
      fssaiNo: $fssaiNo
      fssaiUrl: $fssaiUrl
      licenseUrl: $licenseUrl
      aadharUrl: $aadharUrl
      bankDetails: $bankDetails
      minAmount: $minAmount
      firstName: $firstName
      lastName: $lastName
      mobileNumber: $mobileNumber
      secondaryNumber: $secondaryNumber
      emailAddress: $emailAddress
      password: $password
      GSTnumber: $GSTnumber
      storeCoordinates: $storeCoordinates
      storeLocation: $storeLocation
      storeName: $storeName
      storeDescription: $storeDescription
      storeLogoUrl: $storeLogoUrl
      holidays: $holidays
      storeManagerFirstName: $storeManagerFirstName
      storeManagerLastName: $storeManagerLastName
      storeManagerMobile: $storeManagerMobile
      storeManagerPhone: $storeManagerPhone
      deliveryType: $deliveryType
      deliveryMaxDistance: $deliveryMaxDistance
      deliveryCharge: $deliveryCharge
      paymentSupported: $paymentSupported
      deliveryDays: $deliveryDays
      businessTypeId: $businessTypeId
      openingTime: $openingTime
      closingTime: $closingTime
      subscription: $subscription
      sameDayDelivery: $sameDayDelivery
      deliveryAfter: $deliveryAfter
    ) {
      id
    }
  }
`;
export const SIGNUP_G = gql`
  mutation (
    $firstName: String!
    $lastName: String!
    $mobileNumber: String!
    $secondaryNumber: String
    $emailAddress: String!
    $password: String!
    $PANnumber: String
    $holidays: [Int]
    $storeCoordinates: locationInput!
    $storeLocation: String!
    $storeName: String!
    $storeDescription: String!
    $storeLogoUrl: String!
    $storeManagerFirstName: String!
    $storeManagerLastName: String!
    $storeManagerMobile: String!
    $storeManagerPhone: String
    $deliveryType: [String]!
    $deliveryMaxDistance: Float!
    $deliveryCharge: [deliveryChargeInput]
    $paymentSupported: [String]!
    $deliveryDays: [deliveryDaysInput]!
    $businessTypeId: [Int]
    $minAmount: Float
    $openingTime: String
    $closingTime: String
    $fssaiNo: String
    $fssaiUrl: String
    $licenseUrl: String
    $aadharUrl: String
    $GSTurl: String
    $subscription: subscriptionInput!
    $bankDetails: bankDetailsInput
    $sameDayDelivery: Boolean
    $deliveryAfter: Int
  ) {
    createRetailer(
      GSTurl: $GSTurl
      fssaiNo: $fssaiNo
      fssaiUrl: $fssaiUrl
      licenseUrl: $licenseUrl
      aadharUrl: $aadharUrl
      firstName: $firstName
      lastName: $lastName
      mobileNumber: $mobileNumber
      secondaryNumber: $secondaryNumber
      emailAddress: $emailAddress
      password: $password
      PANnumber: $PANnumber
      holidays: $holidays
      storeCoordinates: $storeCoordinates
      storeLocation: $storeLocation
      storeName: $storeName
      storeDescription: $storeDescription
      storeLogoUrl: $storeLogoUrl
      storeManagerFirstName: $storeManagerFirstName
      storeManagerLastName: $storeManagerLastName
      storeManagerMobile: $storeManagerMobile
      storeManagerPhone: $storeManagerPhone
      deliveryType: $deliveryType
      deliveryMaxDistance: $deliveryMaxDistance
      deliveryCharge: $deliveryCharge
      paymentSupported: $paymentSupported
      deliveryDays: $deliveryDays
      businessTypeId: $businessTypeId
      minAmount: $minAmount
      openingTime: $openingTime
      closingTime: $closingTime
      subscription: $subscription
      bankDetails: $bankDetails
      sameDayDelivery: $sameDayDelivery
      deliveryAfter: $deliveryAfter
    ) {
      id
    }
  }
`;

export const SIGNUP_PG = gql`
  mutation (
    $firstName: String!
    $lastName: String!
    $mobileNumber: String!
    $secondaryNumber: String
    $emailAddress: String!
    $password: String!
    $holidays: [Int]
    $storeCoordinates: locationInput!
    $storeLocation: String!
    $storeName: String!
    $storeDescription: String!
    $storeLogoUrl: String!
    $storeManagerFirstName: String!
    $storeManagerLastName: String!
    $storeManagerMobile: String!
    $storeManagerPhone: String
    $deliveryType: [String]!
    $deliveryMaxDistance: Float!
    $deliveryCharge: [deliveryChargeInput]
    $paymentSupported: [String]!
    $deliveryDays: [deliveryDaysInput]!
    $businessTypeId: [Int]
    $minAmount: Float
    $openingTime: String
    $closingTime: String
    $fssaiNo: String
    $fssaiUrl: String
    $licenseUrl: String
    $aadharUrl: String
    $GSTurl: String
    $subscription: subscriptionInput!
    $bankDetails: bankDetailsInput
    $sameDayDelivery: Boolean
    $deliveryAfter: Int
  ) {
    createRetailer(
      GSTurl: $GSTurl
      fssaiNo: $fssaiNo
      fssaiUrl: $fssaiUrl
      licenseUrl: $licenseUrl
      aadharUrl: $aadharUrl
      firstName: $firstName
      lastName: $lastName
      mobileNumber: $mobileNumber
      secondaryNumber: $secondaryNumber
      emailAddress: $emailAddress
      bankDetails: $bankDetails
      password: $password
      holidays: $holidays
      storeCoordinates: $storeCoordinates
      storeLocation: $storeLocation
      storeName: $storeName
      storeDescription: $storeDescription
      storeLogoUrl: $storeLogoUrl
      storeManagerFirstName: $storeManagerFirstName
      storeManagerLastName: $storeManagerLastName
      storeManagerMobile: $storeManagerMobile
      storeManagerPhone: $storeManagerPhone
      deliveryType: $deliveryType
      deliveryMaxDistance: $deliveryMaxDistance
      deliveryCharge: $deliveryCharge
      paymentSupported: $paymentSupported
      deliveryDays: $deliveryDays
      businessTypeId: $businessTypeId
      minAmount: $minAmount
      openingTime: $openingTime
      closingTime: $closingTime
      subscription: $subscription
      sameDayDelivery: $sameDayDelivery
      deliveryAfter: $deliveryAfter
    ) {
      id
    }
  }
`;

export const AADHAR_UPLOAD = gql`
  mutation ($file: Upload, $size: Int, $filename: String, $type: Int) {
    imageEditAndUpload(
      file: $file
      size: $size
      filename: $filename
      type: $type
    ) {
      ETag
      Location
    }
  }
`;
export const GST_UPLOAD = gql`
  mutation ($file: Upload, $size: Int, $filename: String, $type: Int) {
    imageEditAndUpload(
      file: $file
      size: $size
      filename: $filename
      type: $type
    ) {
      ETag
      Location
    }
  }
`;

export const DELETE_S3 = gql`
  mutation ($fileName: String!) {
    deleteFromS3(fileName: $fileName)
  }
`;
export const SEND_OTP = gql`
  mutation sendOTP($phoneNumber: String!) {
    sendOTP(phoneNumber: $phoneNumber)
  }
`;

export const SELLER_REG = gql`
  mutation retailerSignup(
    $emailId: String!
    $mobileNumber: String!
    $otp: Int!
    $password: String!
    $storeName: String!
    $fcmToken: String
  ) {
    retailerSignup(
      emailId: $emailId
      mobileNumber: $mobileNumber
      otp: $otp
      password: $password
      storeName: $storeName
      fcmToken: $fcmToken
    ) {
      retailerid
      storeid
      token
      tokenExpiration
      storeStatus
      formNumber
    }
  }
`;

export const SIGNUP_FORM1 = gql`
  mutation sellerSignUpForm1(
    $firstName: String!
    $lastName: String!
    $secondaryNumber: String
    $PANnumber: String
    $GSTnumber: String
    $storeManagerFirstName: String!
    $storeManagerLastName: String!
    $storeManagerMobile: String!
    $storeManagerPhone: String
    $bankDetails: bankDetailsInput!
    $profileImageUrl: String
    $aadharNumber: String
  ) {
    sellerSignUpForm1(
      firstName: $firstName
      lastName: $lastName
      secondaryNumber: $secondaryNumber
      PANnumber: $PANnumber
      GSTnumber: $GSTnumber
      storeManagerFirstName: $storeManagerFirstName
      storeManagerLastName: $storeManagerLastName
      storeManagerMobile: $storeManagerMobile
      storeManagerPhone: $storeManagerPhone
      bankDetails: $bankDetails
      profileImageUrl: $profileImageUrl
      aadharNumber: $aadharNumber
    )
  }
`;
export const BANK_DETAILS_INPUT = gql`
  input bankDetailsInput {
    bankAccountNumber: String!
    accountHolderName: String!
    IFSCcode: String!
  }
`;

export const SIGNUP_FORM2 = gql`
  mutation sellerSignUpForm2(
    $storeName: String
    $storeDescription: String!
    $businessTypeId: [Int]!
    $storeLocation: String!
    $storeLogoUrl: String!
    $storeCoordinates: locationInput!
  ) {
    sellerSignUpForm2(
      storeName: $storeName
      storeDescription: $storeDescription
      businessTypeId: $businessTypeId
      storeLocation: $storeLocation
      storeLogoUrl: $storeLogoUrl
      storeCoordinates: $storeCoordinates
    )
  }
`;

export const SIGNUP_FORM3 = gql`
  mutation sellerSignUpForm3(
    $deliveryType: [String]!
    $minAmount: Float!
    $openingTime: String!
    $closingTime: String!
    $holidays: [Int]
    $deliveryDays: [deliveryDaysInput]!
    $deliveryMaxDistance: Float!
    $deliveryCharge: [deliveryChargeInput]!
    $sameDayDelivery: Boolean
    $packingCharge: Float
    $deliveryAfter: Int
	$preparationTime: Float
  ) {
    sellerSignUpForm3(
      deliveryType: $deliveryType
      minAmount: $minAmount
      openingTime: $openingTime
      closingTime: $closingTime
      holidays: $holidays
      deliveryDays: $deliveryDays
      deliveryMaxDistance: $deliveryMaxDistance
      deliveryCharge: $deliveryCharge
      sameDayDelivery: $sameDayDelivery
      packingCharge: $packingCharge
      deliveryAfter: $deliveryAfter
	  preparationTime: $preparationTime
    )
  }
`;

export const SIGNUP_FORM4 = gql`
  mutation sellerSignUpForm4(
    $aadharUrl: String
    $GSTurl: String
    $licenseUrl: String
    $fssaiNo: String
    $fssaiUrl: String
  ) {
    sellerSignUpForm4(
      aadharUrl: $aadharUrl
      GSTurl: $GSTurl
      licenseUrl: $licenseUrl
      fssaiNo: $fssaiNo
      fssaiUrl: $fssaiUrl
    )
  }
`;
